<template>
  <div>
    <!-- Megaport logo with git version tooltip -->
    <el-tooltip placement="bottom"
      :content="`${apiName} ${ $t('general.version') } ${gitVersion}`"
      :open-delay="2000">
      <img src="@/assets/images/megaport-logos/Megaport-Logo-Web-72dpi-RGB.png"
        alt="Megaport logo"
        class="d-block m-auto mb-2"
        width="240"
        height="69">
    </el-tooltip>
    <el-form ref="loginStart"
      :model="loginStart"
      data-name="login-form"
      :rules="loginRules"
      @submit.native.prevent>
      <fieldset class="auth-fieldset">
        <legend>{{ $t('authentication.login-to-your-account') }}</legend>

        <el-form-item :label="$t('general.email')"
          prop="username">
          <el-input ref="loginStartUsername"
            v-model="loginStart.username"
            data-testid="login-username-input"
            name="email"
            type="email"
            autocomplete="email"
            :placeholder="$t('general.email')"
            @keyup.enter.native="federate" />
        </el-form-item>

        <div class="mt-2">
          <el-button name="login"
            type="primary"
            class="full-width"
            :disabled="loggingIn"
            data-testid="login-username-pw-button"
            @click="federate">
            {{ $t('authentication.continue') }}
          </el-button>

          <signup-link class="mt-2" />
          <login-help-link class="d-block mt-1" />

          <h5 class="or">
            <span>{{ $t('authentication.or') }}</span>
          </h5>

          <google-button v-if="allowsGoogleLogin"
            class="mt-1" />
        </div>
      </fieldset>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validateForm } from '@/utils/form.js'
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'

import integrations from '@/third-party-integrations/integrations.js'
import gitVersion from '@/gitVersion.js'
import sdk from '@megaport/api-sdk'

import GoogleButton from '@/components/authentication/GoogleButton.vue'
import SignupLink from '@/components/authentication/signup/SignupLink.vue'
import LoginHelpLink from '@/components/authentication/login/LoginHelpLink.vue'

export default {
  name: 'LoginStart',

  components: {
    'google-button': GoogleButton,
    'signup-link': SignupLink,
    'login-help-link': LoginHelpLink,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      loginStart: {
        username: localStorage.getItem('_username') || '',
      },
      loginRules: {
        username: [
          { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
      },
    }
  },

  computed: {
    ...mapState('Auth', ['loggingIn']),
    allowsGoogleLogin() {
      return (process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'false')
    },

    apiName() {
      return this.$appConfiguration.apiName
    },

    gitVersion() {
      return gitVersion
    },
  },

  methods: {
    ...mapActions('Auth', ['login']),

    handleError(error) {
      integrations.sentry.captureException(error)
      const errorMessage = error?.data?.message ?? this.$t('general.unexpected-error')
      showModalAlert(errorMessage, this.$t('authentication.failed'), 'error')
    },

    async federate() {
      try {
        await validateForm(this.$refs.loginStart)
      } catch {
        return /* error displays inline if this fails */
      }

      const username = this.loginStart.username.trim()
      localStorage.setItem('_username', username)

      try {
        const { status, idp, hasExternalProviderIdentity } = await sdk.instance.federate(username)

        if (status === 'SSO') {
          // If this is the first time they login, they won't have an externalIdentityProvider linked, so show alert to explain what's about to happen
          if (!hasExternalProviderIdentity) {
            try {
              await this.$alert(this.$t('authentication.sso-first-login'))
            } catch {
              /* do nothing */
            }
          }

          integrations.awsAmplify.federatedSignIn({ customProvider: idp.name }).catch(this.handleError)
          return
        }

        this.$router.push('/login/username')
      } catch (failObj) {
        if (failObj.handled || this.destroyed) return

        console.error(failObj)

        let errorMessage = ''
        let errorLevel = 'error'

        if (failObj.status === 500) {
          errorMessage = this.$t('general.unexpected-error')
        } else if (failObj.status === 409) {
          // User is not verified, redirect to verification page
          return this.$router.push('/verify-email')
        } else if (failObj.status === 401 || failObj.data?.message) {
          errorMessage = failObj.data?.message
        }

        this.$refs.loginStartUsername?.blur()
        showModalAlert(errorMessage, this.$t('authentication.failed'), errorLevel)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 0;
}
</style>
