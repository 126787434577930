<template>
  <main>
    <language-dropdown />

    <el-row>
      <!-- Left column (image, about button) -->
      <el-col :sm="10"
        :md="12"
        :lg="13"
        class="img-col">
        <div>
          <div class="flex-column py-0 mp-img">
            <img src="@/assets/images/login-image.svg"
              class="full-width m-auto"
              :alt="$t('images.megaport-cloud-providers-logo')">
            <el-button v-if="!disabledFeatures.megaportMarketing" 
              class="m-auto"
              type="primary"
              @click="showMegaportInfo = true">
              <i class="el-icon-info"
                aria-hidden="true" /> {{ $t('authentication.about-company') }}
            </el-button>
          </div>
        </div>
        <about-megaport v-if="!disabledFeatures.megaportMarketing"
          :visible.sync="showMegaportInfo" />
      </el-col>

      <!-- Right column (login form) -->
      <el-col :xs="24"
        :sm="14"
        :md="12"
        :lg="11"
        class="form-col">
        <div class="m-auto auth-wrapper">
          <!-- Login form -->
          <el-card v-loading="loggingIn"
            shadow="always"
            data-name="loadingProgress">
            <login-start />
          </el-card>
        </div>
      </el-col>
    </el-row>
  </main>
</template>

<script>
window.newRegister = window.newRegister || false

import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

import LoginStart from './login/LoginStart.vue'
import LanguageDropdown from '@/components/ui-components/LanguageDropdown.vue'
import AboutComponent from '@/components/AboutMegaport.vue'

export default {
  name: 'UserAuthentication',

  components: {
    'about-megaport': AboutComponent,
    'login-start': LoginStart,
    'language-dropdown': LanguageDropdown,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      hideSignupLink: false,
      loginProgressMessage: '',
      showMegaportInfo: false,
      destroyed: false, // Protect against processing the promise response if component is going away
    }
  },

  computed: {
    ...mapState('Auth', { accessToken: state => state.data.accessToken }),
    ...mapGetters('Auth', ['isDistributorAccount', 'hasCompany', 'hasFeatureFlag', 'requiresMfaSetup', 'homePage']),
    /**
     * Returns true if app is currently logging in user
     */
    loggingIn() {
      return this.$store.state.Auth.loggingIn
    },

    /**
     * Check env variable for allowing Google logins
     */
    allowsGoogleLogin() {
      return (process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'false')
    },
  },

  created() {
    // aws authorisation redirect from cognito with code and state
    if (this.accessToken) {
      this.gotoHomePage()
      return
    }

    const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))

    const sessionToken = localStorage.getItem('_token')
    const accessToken = localStorage.getItem('_accessToken') ?? cookies.get('mp1AccessToken')?.replace(/^Bearer /, '')

    if (sessionToken || accessToken) {
      const loading = this.$loading({
        lock: true,
        text: this.$t('authentication.trying-login'),
        background: process.env.VUE_APP_BYPASS_AUTHENTICATION === 'true'
          ? '#f8f8f8'
          : 'rgba(255, 255, 255, 0.9)',
      })

      this.setLoggingIn(true)

      const payload = sessionToken ? { session: sessionToken } : { accessToken }
      this.login({ payload })
        .then(async () => {
          /* If no company name is set on login, redirect. This would be caught by the router guard, but stops us doing
           * an extra un-needed call to fetch all services at the cost of some code duplication!
           */
          if (!this.hasCompany) {
            loading.close()
            return this.$router.push('/company-trading-name', () => { /* empty */ })
          }

          loading.setText(this.$t('services.loading-services'))


          if (!this.requiresMfaSetup) await this.getMyServices()
          this.gotoHomePage()
        })
        .catch(() => {
          // Login failed.
          // Do nothing since we are already on the login page
        }).finally(() => {
          this.setLoggingIn(false)
          loading.close()
        })
    }
  },

  beforeDestroy() {
    this.destroyed = true
  },

  methods: {
    ...mapMutations('Auth', ['setLoggingIn']),
    ...mapActions('Services', ['getMyServices']),
    ...mapActions('Auth', ['login']),
    setLoginProgressMessage(message) {
      this.loginProgressMessage = message
    },

    /**
     * Execute animation for flipping card
     * @param {html} el Element to flip
     */
    flipBeforeLeave(el) {
      let rect = el.getBoundingClientRect()
      el.style.width = `${rect.right - rect.left}px`
      el.style.height = `${rect.bottom - rect.top}px`
      el.style.position = 'fixed'
      el.style.top = `${rect.top}px`
      el.style.left = `${rect.left}px`
    },

    gotoHomePage() {
      this.$router.push(this.homePage)
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-img {
  padding-inline: 5rem;

  img {
    max-height: calc(100vh - 100px);
    object-fit: contain;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 992px) {
    padding-inline: 0;
    margin-block: 1.5rem;
  }
}

.el-button.full-width + .el-button.full-width {
  margin-left: 0;
}

.el-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 90vh;

  @media (max-width: 767px) {
    .form-col {
      order: 1;
    }
    .img-col {
      order: 2;
    }
  }
}

.form-col {
  padding-inline: 8rem;

  @media (max-width: 1200px) {
    padding-inline: 4rem;
  }
  @media (max-width: 768px) {
    padding-inline: 1rem;
  }
}

.el-card {
  border-radius: 10px;
  margin: auto;
}

/* This keeps the card position consistent between screens */
.auth-wrapper {
  min-height: 650px;
}

/* Because of google! */
.auth-wrapper .el-card {
  width: 420px;
}

/* match AuthCard styling */
::v-deep .el-card__body {
  padding: 30px;
}
</style>
