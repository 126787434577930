<template>
  <el-button type="text"
    class="p-0"
    data-testid="login-help-button"
    @click="$router.push('/login/help')">
    {{ $t('authentication.login-help') }}
  </el-button>
</template>

<script>
export default {
  name: 'LoginHelpLink',
}
</script>
