<template>
  <div>
    <a href="https://docs.megaport.com/setting-up/inviting-users/"
      class="button-font el-button el-button--text p-0 text-decoration-none"
      data-testid="signup-docs-link"
      target="_blank"
      rel="noopener">{{ $t('authentication.join-company-account') }}</a>

    <el-button class="p-0 full-width text-align-left mt-1"
      type="text"
      data-testid="signup-link-button"
      @click="signupLink">
      {{ $t('authentication.create-new-account') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'SignupLink',

  computed: {
    allowsGoogleLogin() {
      return (process.env.VUE_APP_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'false')
    },
  },

  methods: {
    /**
     * Skip signup selection screen and go to email signup if google login not enabled
     */
    signupLink() {
      return this.allowsGoogleLogin ? this.$router.push('/signup') : this.$router.push('/signup-email')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 0;
}
</style>
