<template>
  <div ref="googleButton"
    data-testid="google-sign-in-button" />
</template>
<script>
import integrations from '@/third-party-integrations/integrations.js'
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { LOGIN_COGNITO, LOGIN_GOOGLE } from '@/Globals.js'

export default {
  name: 'GoogleButton',

  props: {
  // After successfully logging in, can override the default path if required here.
    onSuccessRoute: {
      type: String,
      required: false,
      default: '',
    },

    showPrompt: {
      type: Boolean,
      default: true,
    },

    // https://developers.google.com/identity/gsi/web/reference/js-reference#shape
    shape: {
      type: String,
      default: 'rectangular',
      validator(value) {
        return ['rectangular', 'square', 'circle', 'pill'].includes(value)
      },
    },

    // https://developers.google.com/identity/gsi/web/reference/js-reference#text
    text: {
      type: String,
      default: 'signin_with',
      validator(value) {
        return ['signin_with', 'signup_with', 'continue_with', 'signin'].includes(value)
      },
    },

    // https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
    // These could move to their own props if this gets used enough, but for now left as object.
    buttonConfig: {
      type: Object,
      default() {
        return {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          width: 360,
          context: 'signin',
          locale: this.$i18n.locale,
          logo_alignment: 'center',
        }
      },
    },
  },

  data() {
    return {
      loader: '',
    }
  },

  computed: {
    ...mapState('Auth', ['loggingIn', 'loggedIn', 'googleSignInReady']),
    ...mapGetters('Auth', ['hasCompany', 'requiresMfaSetup', 'homePage', 'hasFeatureFlag']),
  },

  watch: {
    googleSignInReady(newValue) {
      if (newValue) this.googleInit()
    },
  },

  mounted() {
    if (this.googleSignInReady) this.googleInit()
  },

  methods: {
    ...mapActions('Auth', ['login']),
    ...mapMutations('Auth', ['setLoggingIn']),
    ...mapMutations(['setG2NewLogin']),
    ...mapActions('Services', ['getMyServices']),

    googleInit() {
      window.google.accounts.id.initialize({
        client_id: this.$appConfiguration.googleLogin.clientId,
        callback: this.googleLogin,
      })

      if (this.showPrompt) {
        window.google.accounts.id.prompt(() => {
          if (this.loggedIn || this.loggingIn) window.google.accounts.id.cancel()
        })
      }

      window.google.accounts.id.renderButton(this.$refs.googleButton, {
        text: this.text,
        shape: this.shape,
        ...this.buttonConfig,
      })
    },

    googleLogin(response) {
      this.setLoggingIn(true)

      this.loader = this.$loading({
        lock: true,
        text: this.$t('authentication.logging-in'),
        background: 'rgba(255, 255, 255, 1)',
      })

      const payload = { network: LOGIN_GOOGLE, id_token: response.credential }

      this.login({ payload, setLoginState: false })
        .then(this.googleLoginSuccess)
        .catch(this.googleLoginError)
        .finally(() => this.setLoggingIn(false))
    },

    /* Once logged in, check if the FF for cognito is enabled and decide on which flow to use based on that */
    async googleLoginSuccess(data) {
      try {
        // As part of the new MFA flow, we need to call /idp/login to finalise
        // the login, after the /social/google/login call.
        const { accessToken, idToken } = data.oAuthToken
        const payload = { access_token: accessToken, id_token: idToken, network: LOGIN_COGNITO }

        await this.login({ payload })

        if (!this.hasCompany) {
          this.$router.push({ name: 'CompanyTradingName' }, () => { /* empty */ })
          this.loader.close()
          return
        }

        if (this.onSuccessRoute) {
          this.$router.push(this.onSuccessRoute)
          this.loader.close()
          return
        }

        this.completeLogin()
      } catch (error) {
        this.loader.close()

        if (error.data?.data === '2FA') {
          this.setLoggingIn(false)
          this.$router.push('/mfa')
          return
        }

        this.handleError(error)
      }
    },

    /* 307 response indicates the auth FF enabled in backend and that we should migrate the user to Cognito */
    googleLoginError(error) {
      if (error.status === 307) {
        integrations.awsAmplify.federatedSignIn().catch(this.handleError)
        return
      }

      this.loader.close()

      if (error.status === 403) {
        showModalAlert(this.$t('authentication.sso-enforced-no-access'), this.$t('authentication.failed'), 'error')
        return
      }

      this.handleError(error)
    },

    handleError(error) {
      integrations.sentry.captureException(error)
      let errorMessage = error?.data?.message ?? this.$t('general.unexpected-error')
      showModalAlert(errorMessage, this.$t('authentication.failed'), 'error')
    },

    /**
     * We need to know how many services they have before logging in
     * so as to redirect them to dashboard or services
     */
    async completeLogin() {
      if (this.requiresMfaSetup) {
        this.$router.push('/setup-mfa')
        this.loader.close()
        return
      }

      this.setG2NewLogin(true)

      const loadingText = this.$t('services.loading-services')
      this.loader.setText(loadingText)

      try {
        await this.getMyServices()
      } finally {
        this.$router.push(this.homePage)
        this.loader.close()
        this.setLoggingIn(false)
      }
    },
  },
}
</script>
