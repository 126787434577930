<template>
  <el-dialog id="about"
    :visible="visible"
    :before-close="handleClose"
    :title="$t('about.why')"
    top="4vh">
    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.close') }}
      </el-button>
    </template>
    <div>
      <div v-if="visible"
        class="videoWrapper">
        <iframe :title="$t('about.about-megaport')"
          :aria-describedby="$t('about.about-megaport-video')"
          width="560"
          height="315"
          src="https://megaport.wistia.com/medias/fa1vv98361"
          allow="autoplay; encrypted-media"
          allowfullscreen />
      </div>
      <table :aria-describedby="$t('about.megaport-features')">
        <tbody>
          <tr>
            <th scope="row"
              class="text-align-center">
              <mu-mega-icon icon="Scalable"
                class="about-image" />
            </th>
            <td>
              <h3>{{ $t('about.scalable') }}</h3>
              {{ $t('about.scalable-content') }}
            </td>
          </tr>
          <tr>
            <th scope="row"
              class="text-align-center">
              <mu-mega-icon icon="RealTime"
                class="about-image" />
            </th>
            <td>
              <h3>{{ $t('about.real-time') }}</h3>
              {{ $t('about.provisioning') }}
            </td>
          </tr>
          <tr>
            <th scope="row"
              class="text-align-center">
              <mu-mega-icon icon="PowerfulApi"
                class="about-image" />
            </th>
            <td>
              <h3>{{ $t('about.api') }}</h3>
              {{ $t('about.api-content') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-dialog>
</template>

<script>
import { captureEvent } from '@/utils/analyticUtils'
// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) captureEvent('login.about-megaport.click')
    },
  },
  methods: {
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
.about-image {
  height: auto;
  width: 120px;
}
</style>
